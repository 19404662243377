import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import ImagenPlatoRevolver5080 from '../components/imageComponents/imagenPlatoRevolver5080'

const IndexPage = () => (
  <Layout>
    <SEO title='Plato revolver 5080' />
    <Header siteTitle='OR-PRINTER Plato revolver 5080' />

    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6 text-center'>
          <ImagenPlatoRevolver5080 />
        </div>
        <div className='col-12 col-md-6 conmargen'>
          <h3 className={Estilos.username}>Especificaciones Técnicas:</h3>
          <ul className='aplicaciones'>
            <li>Máquina semiautomática accionada por motor eléctrico.</li>
            <li>Alimentación eléctrica: 230 V~.</li>
            <li>Potencia eléctrica máxima: 750 W.</li>
            <li>Presión Máxima: 1300 kgs.</li>
            <li>Altura máxima de la pieza: 65 mm.</li>
            <li>Área máxima de impresión: 140 x 80 mm.</li>
            <li>Sistemas de trabajo con plato revolver o pedal.</li>
            <li>Cadencia de trabajo de hasta 1300 impresiones / hora.</li>
            <li>Regulador de temperatura digital P.I.D. hasta 300&deg; C. </li>
            <li>Pantalla de control de menús con 20 memorias</li>
            <li>
              Avance de película por motor eléctrico controlado por temporizador
              electrónico.
            </li>
            <li>Cabezal autobasculante, regulable en altura e inclinación.</li>
            <li>Contador digital progresivo, regresivo y totalizador.</li>
            <li>Iluminación de la zona de trabajo mediante LED ́S.</li>
            <li>Peso: 82 Kg.</li>
            <li>Dimensiones: Alto: 81 cms. Ancho: 46 cms. Fondo: 86 cms.</li>
          </ul>
        </div>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
